<template>
  <div style="padding-top: 1rem">
    <div class="reports_filter px-5 mb-8">
      <v-form ref="form" class="d-flex align-center mb-5">
        <span v-if="!isLoading">
          <small>Jornada: </small>
          <strong v-if="!isLoading"
            >{{ currentJourney?.journey?.name || "" }}
          </strong>
        </span>
      </v-form>

      <z-filter
        :interval-filters-list-prop="intervalFilters"
        :tempaltes-list-prop="templates"
        :filter-options-prop="itemsFilterList"
        @chip-created="onUpdateChipList"
        @remove-all-chips="onRemoveAllChips"
        @remove-single-chip="onUpdateChipList"
        @update-chip="onUpdateChipList"
        @remove-child-chip="onUpdateChipList"
      />
    </div>

    <div v-if="!isLoading" class="wrapper__card mx-5">
      <div class="wrapper__card__body">
        <v-row class="px-3 mb-3 d-flex align-center justify-space-between">
          <div class="wrapper__card__body__header">
            <div
              v-for="item in metricsTypes"
              :key="item.id + item.type"
              class="metric-type mb-2"
            >
              <div class="dot" :style="{ background: item.color }"></div>
              <small :style="{ color: item.color }">{{ item.type }}</small>
            </div>
          </div>

          <div>
            <z-btn
              rounded
              primary
              text="Extração via api"
              icon="$upload_file"
              small
              class="mr-2"
              @click="$router.push(`/dataExtraction/${$route.params.id}`)"
            />

            <z-btn
              rounded
              primary
              text="Registro de falhas de envio"
              icon="$report_gmailerrorred_rounded"
              small
              @click="
                $router.push(
                  `/reports/journey-reports-fails-details/${$route.params.id}`
                )
              "
            />
          </div>
        </v-row>

        <v-row style="width: 100%">
          <v-col cols="12">
            <h2>Metricas Gerais</h2>
          </v-col>

          <v-col cols="3">
            <!-- MÉTRICAS GERAIS -->
            <CardReport
              icon="$groups"
              main-color-prop="#0273c3"
              border-color-prop="#ffce4d"
            >
              <template #title> AUDIÊNCIAS </template>
              <template #value v-if="loadingAudienceData">
                <z-circular-progress
                  value="10"
                  size="40"
                  width="5"
                  rotate
                  :indeterminate="true"
                />
              </template>
              <template #value v-else>
                {{ formateNumberToLocal(audienceUniqueIdsCounter) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$send"
              main-color-prop="#239465"
              border-color-prop="#ffce4d"
            >
              <template #title> TOTAL DE ENVIOS </template>
              <template #value>
                {{ formateNumberToLocal(+totalEmailSent + +totalSMSSent) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$unsubscribe"
              main-color-prop="#239465"
              border-color-prop="#ffce4d"
            >
              <template #title> OPTOUTS REALIZADOS </template>
              <template #value
                >{{ formateNumberToLocal(totalOptOuts) || 0 }} ({{
                  +totalEmailSent !== 0
                    ? ((+totalOptOuts / +totalEmailSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
        </v-row>

        <v-row style="width: 100%">
          <v-col cols="12">
            <h2>Metricas SMS</h2>
          </v-col>

          <v-col cols="3">
            <CardReport
              icon="$send"
              main-color-prop="#ffc220"
              border-color-prop="#30895e"
            >
              <template #title> TELEFONES OPT-OUT </template>
              <template #value v-if="loadingOptoutData">
                <z-circular-progress
                  value="10"
                  size="40"
                  width="5"
                  rotate
                  :indeterminate="true"
                />
              </template>
              <template #value v-else>
                {{ formateNumberToLocal(phonesInOptOut) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$send"
              main-color-prop="#ffc220"
              border-color-prop="#30895e"
            >
              <template #title> TELEFONES VÁLIDOS </template>
              <template #value v-if="loadingOptoutData">
                <z-circular-progress
                  value="10"
                  size="40"
                  width="5"
                  rotate
                  :indeterminate="true"
                />
              </template>
              <template #value v-else>
                {{ formateNumberToLocal(audienceValidPhones) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <!-- METRICAS DE SMS -->
            <CardReport
              icon="$send"
              main-color-prop="#ffc220"
              border-color-prop="#30895e"
            >
              <template #title> SMS ENVIADOS </template>
              <template #value>{{
                formateNumberToLocal(totalSMSSent) || 0
              }}</template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$arrow_back"
              main-color-prop="#ff8f28"
              border-color-prop="#30895e"
            >
              <template #title> CLIQUES SMS </template>
              <template #value>
                {{ formateNumberToLocal(totalSMSClicks) || 0 }} ({{
                  +totalSMSSent !== 0
                    ? ((+totalSMSClicks / +totalSMSSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
        </v-row>

        <v-row style="width: 100%">
          <v-col cols="12">
            <h2>Metricas Email</h2>
          </v-col>

          <v-col cols="3">
            <!-- METRICAS DE EMAIL -->
            <CardReport
              icon="$unsubscribe"
              main-color-prop="#674099"
              border-color-prop="#411e6d"
            >
              <template #title> EMAILS OPT-OUT </template>
              <template #value v-if="loadingOptoutData">
                <z-circular-progress
                  value="10"
                  size="40"
                  width="5"
                  rotate
                  :indeterminate="true"
                />
              </template>
              <template #value v-else>
                {{ formateNumberToLocal(emailsInOptOut) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$email"
              main-color-prop="#674099"
              border-color-prop="#411e6d"
            >
              <template #title> EMAILS VÁLIDOS </template>
              <template #value v-if="loadingOptoutData">
                <z-circular-progress
                  value="10"
                  size="40"
                  width="5"
                  rotate
                  :indeterminate="true"
                />
              </template>
              <template #value v-else>
                {{ formateNumberToLocal(audienceValidEmails) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$send"
              main-color-prop="#674099"
              border-color-prop="#411e6d"
            >
              <template #title> EMAILS ENVIADOS </template>
              <template #value>
                {{ formateNumberToLocal(totalEmailSent) || 0 }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$send"
              main-color-prop="#674099"
              border-color-prop="#411e6d"
            >
              <template #title> EMAILS ENTREGUES </template>
              <template #value>
                {{ formateNumberToLocal(totalEmailDelivery) || 0 }} ({{
                  +totalEmailSent !== 0
                    ? ((+totalEmailDelivery / +totalEmailSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-email-open-outline"
              main-color-prop="#239465"
              border-color-prop="#411e6d"
            >
              <template #title> ABERTURAS EMAIL </template>
              <template #value>
                {{ formateNumberToLocal(totalEmailOpens) || 0 }} ({{
                  +totalEmailDelivery !== 0
                    ? ((+totalEmailOpens / +totalEmailDelivery) * 100).toFixed(
                        2
                      )
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$arrow_back"
              main-color-prop="#ff8f28"
              border-color-prop="#411e6d"
            >
              <template #title> CLIQUES EMAIL </template>
              <template #value
                >{{ formateNumberToLocal(totalEmailClicks) || 0 }} ({{
                  +totalEmailOpens !== 0
                    ? ((+totalEmailClicks / +totalEmailDelivery) * 100).toFixed(
                        2
                      )
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-email-sync-outline"
              main-color-prop="#ffc220"
              border-color-prop="#411e6d"
            >
              <template #title> BOUNCES EMAIL </template>
              <template #value
                >{{ formateNumberToLocal(totalEmailBounces) || 0 }} ({{
                  +totalEmailSent !== 0
                    ? ((+totalEmailBounces / +totalEmailSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-alert-octagon-outline"
              main-color-prop="#0273c3"
              border-color-prop="#411e6d"
            >
              <template #title> SPAM EMAIL </template>
              <template #value
                >{{ formateNumberToLocal(totalEmailSpammed) || 0 }} ({{
                  +totalEmailSent !== 0
                    ? ((+totalEmailSpammed / +totalEmailSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$reply"
              main-color-prop="#ffc220"
              border-color-prop="#411e6d"
            >
              <template #title> RESPOSTAS EMAIL </template>
              <template #value
                >{{ formateNumberToLocal(totalEmailSpammed) || 0 }} ({{
                  +totalEmailSent !== 0
                    ? ((+totalEmailSpammed / +totalEmailSent) * 100).toFixed(2)
                    : 0
                }}%)</template
              >
            </CardReport>
          </v-col>
        </v-row>

        <v-row style="width: 100%">
            <v-col cols="12">
              <h2>Metricas Whatsapp</h2>
            </v-col>

            <v-col cols="3">
              <CardReport
                icon="mdi-whatsapp"
                main-color-prop="rgba(73, 203, 140, 1)"
                border-color-prop="#53A9D8"
              >
                <template #title> TOTAL DE ENVIOS </template>
                <template #value>
                  {{ formateNumberToLocal(whatsappInfosCards?.count_total) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-check"
                main-color-prop="rgb(105, 188, 203)"
                border-color-prop="#53A9D8"
              >
                <template #title> ENTREGAS WPP </template>
                <template #value>
                  {{
                    formateNumberToLocal(whatsappInfosCards?.count_delivered)
                  }}
                  {{
                    formatePercentToChips(
                      whatsappInfosCards?.count_delivered,
                      whatsappInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-open-outline"
                main-color-prop="rgba(73, 203, 140, 1)"
                border-color-prop="#53A9D8"
              >
                <template #title> LEITURAS WPP </template>
                <template #value>
                  {{ formateNumberToLocal(whatsappInfosCards?.count_opened) }}
                  {{
                    formatePercentToChips(
                      whatsappInfosCards?.count_opened,
                      whatsappInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
          </v-row>

        <v-row style="width: 100%">
          <v-col cols="12">
            <h2>Metricas Push</h2>
          </v-col>

          <v-col cols="3">
            <CardReport
              icon="mdi-alert-octagon-outline"
              main-color-prop="rgb(105, 188, 203)"
              border-color-prop="#53A9D8"
            >
              <template #title> TOTAL DE ENVIOS PUSH </template>
              <template #value>{{
                formateNumberToLocal(pushInfosCards?.count_total)
              }}</template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-email-open-outline"
              main-color-prop="rgb(105, 188, 203)"
              border-color-prop="#53A9D8"
            >
              <template #title> ABERTURAS PUSH </template>
              <template #value>
                {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                {{
                  formatePercentToChips(
                    pushInfosCards?.count_opened,
                    pushInfosCards?.count_total
                  )
                }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="$arrow_back"
              main-color-prop="rgba(73, 203, 140, 1)"
              border-color-prop="#53A9D8"
            >
              <template #title> CLIQUES PUSH </template>
              <template #value>
                {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                {{
                  formatePercentToChips(
                    pushInfosCards?.count_clicked,
                    pushInfosCards?.count_total
                  )
                }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-email-sync-outline"
              main-color-prop="rgb(100, 45, 108)"
              border-color-prop="#53A9D8"
            >
              <template #title> BOUNCES PUSH </template>
              <template #value>
                {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                {{
                  formatePercentToChips(
                    pushInfosCards?.count_spam,
                    pushInfosCards?.count_total
                  )
                }}
              </template>
            </CardReport>
          </v-col>
          <v-col cols="3">
            <CardReport
              icon="mdi-alert-octagon-outline"
              main-color-prop="rgb(222, 87, 66)"
              border-color-prop="#53A9D8"
            >
              <template #title> SPAM PUSH </template>
              <template #value>
                {{ formateNumberToLocal(pushInfosCards?.count_opened) }}

                {{
                  formatePercentToChips(
                    pushInfosCards?.count_spam,
                    pushInfosCards?.count_total
                  )
                }}
              </template>
            </CardReport>
          </v-col>
        </v-row>

        <v-row justify="space-around">
          <v-col class="" cols="12">
            <v-card
              elevation="2"
              height="420"
              shaped
              class="mx-2 my-2 py-2 px-5"
            >
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600">JORNADA</span>

                <span>
                  <small class="mr-2"
                    >Início: <strong>{{ formatDate(journey.created) }}</strong>
                  </small>
                  <small
                    >Fim:
                    <strong>{{
                      !journey.cron
                        ? formatDate(journey.created)
                        : journey.cron.endsAt
                        ? formatDate(journey.cron.endsAt)
                        : "Recorrente"
                    }}</strong></small
                  >

                  <template v-if="clickedsNodes.length">
                    <v-icon
                      class="ml-5"
                      color="#004C91"
                      @click="resetFilterByJourney"
                      >$settings_backup_restore</v-icon
                    >
                  </template>
                </span>
              </v-row>
              <div class="tree-component">
                <TreeComponent
                  :key="keyComponent"
                  :database-config-id-prop="databaseConfigId"
                  :nodes="tree"
                  @node_click="treeNodeClicked"
                />
              </div>
            </v-card>
          </v-col>

          <v-col cols="8">
            <v-card
              elevation="2"
              height="420"
              shaped
              class="mx-2 my-2 py-2 px-5"
            >
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600"
                  >BOUNCES/CLIQUE</span
                >
              </v-row>
              <section>
                <BarChart
                  v-if="!bouncesAndClicksChartDataLoading"
                  :chart-data="bouncesAndClicksChartDataInformation"
                  :chart-options="bouncesAndClicksChartOptions"
                />
                <aside
                  v-else
                  class="d-flex align-center justify-center"
                  style="height: 320px"
                >
                  <z-loading />
                </aside>
              </section>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card
              elevation="2"
              height="420"
              shaped
              class="mx-2 my-2 py-2 px-5"
            >
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600">CANAL</span>
                <v-icon
                  v-if="filter.channel"
                  color="#004C91"
                  @click="doughnutChartCancel"
                  >$settings_backup_restore</v-icon
                >
              </v-row>
              <DoughnutChart
                v-if="totalByChannel"
                :labels="['Email', 'SMS']"
                :totals="totalByChannel"
                :chart-info-prop="donutChartData"
              />
            </v-card>
          </v-col>

          <!-- TABELA DE USUARIOS -->
          <v-col cols="12">
            <v-card elevation="2" shaped class="mx-2 my-2 py-2 px-5">
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600"
                  >USUÁRIOS</span
                >
                <div class="px-5 py-4 d-flex justify-end align-center">
                  <z-btn
                    small
                    rounded
                    primary
                    text=""
                    :icon="`mdi-cloud-download`"
                    :is-loading="loadingDownloadUsers"
                    @click="downloadDocument"
                  />
                </div>
              </v-row>
              <v-row>
                <v-col>
                  <z-chip
                    pill
                    color="#1976d2"
                    :class="
                      userStatusFilter === 'delivered'
                        ? 'selected activeChip'
                        : 'inactiveChip'
                    "
                    text="Recebeu"
                    @click="filterUserList('delivered')"
                  />
                  <z-chip
                    color="#1976d2"
                    text="Abriu"
                    :class="
                      userStatusFilter === 'opened'
                        ? 'selected activeChip'
                        : 'inactiveChip'
                    "
                    @click="filterUserList('opened')"
                    style="margin-left: 5px"
                  />
                  <z-chip
                    color="#1976d2"
                    text="Clicou"
                    :class="
                      userStatusFilter === 'clicked'
                        ? 'selected activeChip'
                        : 'inactiveChip'
                    "
                    @click="filterUserList('clicked')"
                    style="margin-left: 5px"
                  />
                  <z-chip
                    color="#1976d2"
                    text="Bounce"
                    :class="
                      userStatusFilter === 'bounced'
                        ? 'selected activeChip'
                        : 'inactiveChip'
                    "
                    @click="filterUserList('bounced')"
                    style="margin-left: 5px"
                  />
                  <z-chip
                    color="#1976d2"
                    text="Opt Out"
                    :class="
                      userStatusFilter === 'optout'
                        ? 'selected activeChip'
                        : 'inactiveChip'
                    "
                    @click="filterUserList('optout')"
                    style="margin-left: 5px"
                  />
                </v-col>
              </v-row>
              <z-pagination
                :auto-pagination="false"
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="10"
                :current-page="usersTableFilter.page"
                :number-of-pages="usersTableFilter.totalPages"
                :items="usersList"
                @click:next="goNextPageUsersList"
                @click:previous="backPreviousPageUsersList"
                @change:page="gotToChoosenPage($event, 'user')"
              >
                <template #data="{ paginatedItems }">
                  <div
                    class="table-wrapper"
                    style="
                      height: 550px !important;
                      max-height: 550px !important;
                    "
                  >
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      :is-loading="loadingUserListTable"
                      @sort="getSortedUsersList($event)"
                    >
                      <template #no-data>
                        <div
                          class="no-data-table d-flex align-end justify-center"
                        >
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>

                      <template v-slot:[`item.created`]="{ item }">
                        <div>
                          <span>
                            {{ formatDate(item.created) }}
                          </span>
                        </div>
                      </template>

                      <template v-slot:[`item.journeyStepId`]="{ item }">
                        <div>
                          <span>
                            {{ getStepIdName(item.journeyStepId) }}
                          </span>
                        </div>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
            </v-card>
          </v-col>

          <!-- TABELA DE TEMPLATES -->
          <v-col cols="12">
            <v-card elevation="2" shaped class="mx-2 my-2 py-2 px-5">
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600"
                  >TEMPLATES</span
                >
                <div class="px-5 py-4 d-flex justify-end align-center">
                  <z-btn
                    small
                    rounded
                    primary
                    text=""
                    :icon="`mdi-cloud-download`"
                    :is-loading="loadingDownloadTemplates"
                    @click="downloadDocumentTemplates"
                  />
                </div>
              </v-row>
              <z-pagination
                :auto-pagination="false"
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="10"
                :items="templates"
                :current-page="templateTableFilter.page"
                :number-of-pages="templateTableFilter.totalPages"
                @click:next="goNextPageTemplatesReportsByCompanyId"
                @click:previous="backPreviousPageTemplatesReportsByCompanyId"
                @change:page="gotToChoosenPage($event, 'template')"
              >
                <template #data="{ paginatedItems }">
                  <div
                    class="table-wrapper"
                    style="
                      height: 550px !important;
                      max-height: 550px !important;
                    "
                  >
                    <z-table
                      class="table"
                      :headers="headersTemplates"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      :is-loading="loadingSortTableTemplate"
                      @sort="getSortedTemplateReportsByCompanyId($event)"
                    >
                      <template #no-data>
                        <div
                          class="no-data-table d-flex align-end justify-center"
                        >
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>

                      <template v-slot:[`item.openRate`]="{ item }">
                        <span>{{
                          item.type === "email"
                            ? item.openRate.toFixed(2) + "%" || "0%"
                            : "-"
                        }}</span>
                      </template>

                      <template v-slot:[`item.totalSent`]="{ item }">
                        <span>{{ item.totalSent || "0" }}</span>
                      </template>

                      <template v-slot:[`item.clickRate`]="{ item }">
                        <span>{{ item.clickRate.toFixed(2) || "0" }}%</span>
                      </template>

                      <template v-slot:[`item.bounceRate`]="{ item }">
                        <span>{{
                          item.type === "email"
                            ? item.bounceRate.toFixed(2) + "%" || "0%"
                            : "-"
                        }}</span>
                      </template>

                      <template v-slot:[`item.spamRate`]="{ item }">
                        <span
                          >{{
                            item.type === "email"
                              ? item.spamRate.toFixed(2) + "%" || "0"
                              : "-"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.optOutRate`]="{ item }">
                        <span
                          >{{
                            item.type === "email"
                              ? item.optOutRate.toFixed(2) + "%" || "0"
                              : "-"
                          }}
                        </span>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="dontHaveAllDatasToCards" class="wrapper__card__overlay">
        <div class="message">
          <span>
            Não foram encontrados resultados com esses filtros. Tente refazer
            sua pesquisa.
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="wrapper__card">
        <v-row justify="center">
          <z-loading />
        </v-row>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable no-undef -->
<script>
import { findNodeById, buildTreeService } from "./utils";
import { computed } from "vue";
import { usersToDetailMock, journeyMock } from "./mock";
import ApiClient from "@/services/ApiClient";
import TreeComponent from "./components/fluxogram/ExampleList.vue";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";
import BarChart from "./components/Bar.vue";
import DoughnutChart from "./components/Doughnut.vue";
import CardReport from "../../Shared/ZCardReportInfo.vue";
import ZFilter from "@/components/Shared/filter/filterContainer.vue";

export default {
  components: {
    BarChart,
    DoughnutChart,
    TreeComponent,
    // DatePicker,
    CardReport,
    ZFilter,
  },
  data() {
    return {
      itemsFilterList: [
        {
          text: "Template",
          value: "templates",
          child_component: "./components/templateList.vue",
          component_name: "templateList.vue",
        },
        {
          text: "Intervalo de tempo",
          value: "interval",
          child_component: "./components/intervalList.vue",
          component_name: "intervalList.vue",
        },
        // {
        //   text: "Ordenação",
        //   value: "order",
        //   child_component: "./components/journeyDetailOrderList.vue",
        //   component_name: "journeyDetailOrderList.vue",
        // },
      ],
      currentTemplateReportUrl: null,
      currentUserListUrl: null,
      loadingSortTableTemplate: false,
      usersTableFilter: {
        page: 1,
        totalPages: 1,
      },
      pushInfosCards: null,
      whatsappInfosCards: null,
      metricsTypes: [
        {
          type: "Métrics Gerais",
          id: 0,
          color: "#FFCE4D",
        },
        {
          type: "Métricas de SMS",
          id: 2,
          color: "#30895E",
        },
        {
          type: "Métricas de E-mail",
          id: 2,
          color: "#411E6D",
        },
        {
          type: "Métricas de Push",
          id: 3,
          color: "#53A9D8",
        },
      ],
      informationsToCardsOnTop: [],
      audienceUniqueIdsCounter: 0,
      audienceValidEmails: 0,
      emailsInOptOut: 0,
      audienceValidPhones: 0,
      phonesInOptOut: 0,
      firstSendDate: null,
      lastSendDate: null,
      sendDuration: null,
      remainingUsers: null,
      clickedsNodes: [],
      bouncesAndClicksChartDataLoading: false,
      bouncesAndClicksChartDataInformation: {},
      bouncesAndClicksChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        //  onClick: (e) => this.getChartClickedInfo(e.chart, e, "send"),
        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },
      pickerRange: "",
      usersList: [],
      donutChartData: null,
      currentClickedNode: null,
      templatesEmailList: [],
      audiencesList: [],
      templatesSMSList: [],
      databaseConfigId: null,
      keyComponent: 0,
      tree: null,
      isTreeLoading: false,
      currentJourney: null,
      journey: null,
      usersToDetailMock,
      journeyMock,
      isFilteringGraph: false,
      isFilteringHour: false,
      loadingSortTable: false,
      loadingUserListTable: false,
      orderMessage: "",
      tableName: "",
      orderName: "",
      closeOnContentClick: false,
      sortDesc: false,
      currentTableUrl: null,
      sendChartDataInformationArr: [],
      engageChartDataInformationArr: [],
      sendChartDataInformation: null,
      sendChartDataInformationLoading: false,
      engageChartDataInformationLoading: false,
      engageChartDataInformation: null,
      sendChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e) => this.getChartClickedInfo(e.chart, e, "send"),
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
          },
        },
      },
      engageChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e) => this.getChartClickedInfo(e.chart, e, "engage"),

        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
            ticks: {
              callback: function (value) {
                return value;
              },
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let A = `${context.parsed.y}`;
                let B = A.replace(".", ",");
                let label = `${context.dataset.label} ${B}%` || "";
                return label;
              },
            },
          },
        },
      },
      selectedInterval: 1,
      loadingDownload: false,
      http: new ApiClient(),
      isLoading: true,
      totalSent: 0,
      loadingDownloadTemplates: false,
      totalEmailSent: 0,
      loadingDownloadUsers: false,
      totalSMSSent: 0,
      totalEmailSpammed: 0,
      totalEmailBounces: 0,
      totalOptOuts: 0,
      totalEmailOpens: 0,
      totalEmailDelivery: 0,
      totalClicks: 0,
      totalEmailClicks: 0,
      totalSMSClicks: 0,
      totalByChannel: 0,
      openRateByDateRange: null,
      clickRateByDateRange: null,
      clicksData: null,
      journeys: null,
      opensData: null,
      journeyFilterSelected: null,
      intervalFilters: [
        {
          name: "Personalizado",
          value: 6,
        },
        {
          name: "Últimos 12 meses",
          value: 1,
          textDate: this.getLastTwelveMonthsUltilDate(),
        },
        {
          name: "Ano até à data",
          value: 2,
          textDate: this.getYearUntilDate(),
        },
        {
          name: "Último ano",
          value: 3,
          textDate: this.getAllLastYear(),
        },
        {
          name: "Mês até à data",
          value: 4,
          textDate: this.getMonthUntilToday(),
        },
        {
          name: "Último mês",
          value: 5,
          textDate: this.getLastMonth(),
        },
        {
          name: "Últimos 30 dias",
          text: "Últimos 30 dias",
          textDate: this.getLasThirtyDays(),
          value: 7,
        },
        {
          name: "Últimos 7 dias",
          text: "Últimos 7 dias",
          textDate: this.getLastSevenDays(),
          value: 8,
        },
      ],
      headersTemplates: [
        { text: "Template", value: "name", sortable: true },
        { text: "Tipo", value: "type", sortable: true },
        { text: "Total de Envios", value: "totalSent", sortable: false },
        { text: "Abertos (%)", value: "openRate", sortable: false },
        { text: "Bounces (%)", value: "bounceRate", sortable: false },
        { text: "Cliques (%)", value: "clickRate", sortable: false },
        { text: "OptOut (%)", value: "optOutRate", sortable: false },
        { text: "Spam (%)", value: "spamRate", sortable: false },
      ],
      headers: [
        { text: "Nome", value: "name", sortable: true },
        { text: "E-mail", value: "email", sortable: true },
        { text: "Celular", value: "phone", sortable: true },
        { text: "Data de criação", value: "created", sortable: true },
        { text: "Última interação", value: "lastInteraction", sortable: false },
      ],
      headersFilter: [
        { text: "Campanha", value: "name", sortable: true },
        { text: "Data de criação", value: "dateCreated", sortable: true },
        { text: "Data último envio", value: "lastSend", sortable: true },
      ],
      order: [
        { text: "Crescente", value: "ASC" },
        { text: "Decrescente", value: "DESC" },
      ],
      templates: [],
      paginatedItems: [],
      paginatedTemplatesItems: [],
      filtered: [],
      loadingAudienceData: false,
      loadingOptoutData: false,
      recent: [],
      filter: {
        stepId: [],
        startsAt: null,
        selectedJourney: [this.$route.params.id],
        journeyId: this.$route.params.id,
        endsAt: null,
        groupBy: "month",
        page: 0,
      },
      templateTableFilter: {
        page: 1,
        totalPages: 1,
      },
      currentClickedNodeId: null,
      totalUsersPages: 1,
      newJourneyInfos: null,
      filters: [],
      userStatusFilter: null,
    };
  },
  provide() {
    return {
      databaseConfigId: computed(() => this.databaseConfigId),
      currentClickedNodeId: computed(() => this.currentClickedNodeId),
      clickedNodesList: computed(() => this.clickedsNodes),
      isToUpdateDiamond: false,
      templateList: computed(() => this.templates),
      intervalList: computed(() => this.intervalFilters),
      templatesFields: computed(() => this.headersTemplates),
      usersFields: computed(() => this.headers),
      orders: computed(() => this.order),
    };
  },
  async created() {
    const INFO = this.intervalFilters.find((e) => e.value == 8);
    this.$store.dispatch("app/CREATE_DEFAULT_CHIP", INFO);
    this.filters = this.$store.getters["app/$chipsFilterList"];
    let dateInformation = this.filters.find(
      (chip) => chip.chip_type === "interval"
    );

    console.log("adsdsa");

    console.log(dateInformation.childs[0].chip_end_date);

    this.filter.endsAt = this.verifyDateAndFormate(
      dateInformation.childs[0].chip_end_date
    );
    this.filter.startsAt = this.verifyDateAndFormate(
      dateInformation.childs[0].chip_start_date
    );

    await this.getAudienceList();
    await this.getTemplatesList();

    // await this.mountFilters();
    await this.chooseGroupBy();

    await this.callAllMethodsFromReport();
  },
  computed: {
    dontHaveAllDatasToCards() {
      const all_datas = [
        this.audienceUniqueIdsCounter?.toLocaleString("pt-BR") || 0,
        this.totalEmailSent || 0,
        this.totalSMSSent || 0,
        this.totalSMSClicks || 0,
        this.totalEmailOpens || 0,
        this.totalEmailClicks || 0,
        this.totalEmailBounces || 0,
        this.totalEmailSpammed || 0,
        this.totalOptOuts || 0,
        this.journeys ? this.journeys.length : 0,
        this.templates ? this.templates.length : 0,
      ];

      const is_all_zero = all_datas.every((el) => el <= 0);

      return is_all_zero;
    },
    hasAnyFilter() {
      return this.selectedInterval !== 1;
    },
    minimumDate() {
      let MIN = "";

      if (this.filter.startsAt) MIN = this.filter.startsAt;
      else MIN = moment(moment.now()).format("YYYY-MM-DD");

      return MIN;
    },
    startDateComputed() {
      let start = "";
      // let position = this.selectedInterval > 5 ? this.selectedInterval - 1 : this.selectedInterval;
      // const choosed = this.intervalFilters[position];
      const choosed = this.intervalFilters.find(
        (d) => d.value == this.selectedInterval
      );
      if (choosed?.textDate)
        start = choosed?.textDate?.split("até")[0].replaceAll("-", "/") || "";
      else start = this.formatDate2(this.filter.startsAt);
      return start;
    },
    endDateComputed() {
      let end = "";
      // let position = this.selectedInterval > 5 ? this.selectedInterval - 1 : this.selectedInterval;
      // const choosed = this.intervalFilters[position];
      const choosed = this.intervalFilters.find(
        (d) => d.value == this.selectedInterval
      );
      if (choosed?.textDate)
        end = choosed?.textDate?.split("até")[1].replaceAll("-", "/") || "";
      else end = this.formatDate2(this.filter.endsAt);

      return end;
    },
  },
  watch: {
    tableName(nv) {
      if (nv && this.orderName) {
        this.orderMessage = `${nv.text}/${this.orderName.text}`;
      }
    },
    orderName(nv) {
      if (nv && this.tableName) {
        this.orderMessage = `${this.tableName.text}/${nv.text}`;
      }
    },
    // async selectedInterval(nv) {
    //   if (nv) {
    //     if (nv !== 6) this.pickerRange = "";
    //     await this.mountFilters();
    //     this.filterReports();
    //   }
    // },
    // "filter.endsAt"(nv) {
    //   if (
    //     nv &&
    //     this.filter.startsAt &&
    //     this.selectedInterval == 6 &&
    //     !this.isFilteringGraph
    //   ) {
    //     const start = moment(this.filter.startsAt, "YYYY-MM-DD");
    //     const end = moment(nv, "YYYY-MM-DD");
    //     const diference = end.diff(start, "days");

    //     if (diference <= 30) this.filter.groupBy = "day";
    //     else this.filter.groupBy = "month";

    //     this.filterReports();
    //   }
    // },
    // "filter.startsAt"(nv) {
    //   if (
    //     nv &&
    //     this.filter.endsAt &&
    //     this.selectedInterval == 6 &&
    //     !this.isFilteringGraph
    //   ) {
    //     const start = moment(nv, "YYYY-MM-DD");
    //     const end = moment(this.filter.endsAt, "YYYY-MM-DD");
    //     const diference = end.diff(start, "days");

    //     if (diference <= 30) this.filter.groupBy = "day";
    //     else this.filter.groupBy = "month";

    //     this.filterReports();
    //   }
    // },
    // "filter.selectedJourney"() {
    //   this.filterReports();
    // },
  },
  methods: {
    validateAndFormateNumber(num) {
      return num ? Number(num) : 0;
    },
    formatePercentToChips(dividend, divisor) {
      const dividend_formated = this.validateAndFormateNumber(dividend);
      const divisor_formated = this.validateAndFormateNumber(divisor);
      let percent = 0;

      if (dividend_formated > 0)
        percent = (dividend_formated / divisor_formated) * 100;

      return `(${percent.toFixed(2)}%)`;
    },
    formateNumberToLocal(num) {
      return num ? parseFloat(num).toLocaleString("pt-BR") : 0;
    },
    async resetFiltersAndReload() {
      this.filters = [];

      const INFO = this.intervalFilters.find((e) => e.value == 1);

      this.$store.dispatch("app/CREATE_DEFAULT_CHIP", INFO);

      this.filters = this.$store.getters["app/$chipsFilterList"];

      let dateInformation = this.filters.find(
        (chip) => chip.chip_type === "interval"
      );

      this.filter.endsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_end_date
      );

      this.filter.startsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_start_date
      );

      this.filter.selectedJourney = null;

      this.filter.selectedTemplate = null;

      await this.chooseGroupBy(dateInformation.childs[0].chip_info_child_id);

      this.callAllMethodsFromReport();
    },
    verifyDateAndFormate(date) {
      const is_valid = moment(date.trim(), "YYYY-MM-DD", true).isValid();

      const correct_date = is_valid
        ? date
        : moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      return correct_date;
    },
    async onUpdateChipList(e) {
      if (!e.length) this.resetFiltersAndReload();
      else {
        this.filters = e;

        let templateParents = e.filter((x) => x.chip_type === "templates");
        let dateInformation = e.find((chip) => chip.chip_type === "interval");

        this.filter.endsAt = this.verifyDateAndFormate(
          dateInformation.childs[0].chip_end_date
        );

        this.filter.startsAt = this.verifyDateAndFormate(
          dateInformation.childs[0].chip_start_date
        );

        if (templateParents && templateParents.length > 0) {
          this.filter.selectedTemplate = templateParents.flatMap((item) =>
            item.childs.map((child) => child.chip_info_child_id)
          );
        } else this.filter.selectedTemplate = null;

        await this.chooseGroupBy(dateInformation.childs[0].chip_info_child_id);

        this.callAllMethodsFromReport();
      }
    },
    onRemoveAllChips() {
      this.resetFiltersAndReload();
    },
    setValuePickerToFilter(evt) {
      this.filter.startsAt = evt[0];
      this.filter.endsAt = evt[evt.length - 1];
    },
    // getStepIdName(id) {
    //   let NAME = ''

    //   const NODE = this.currentJourney.steps.find(el => el.id == id)
    //   console.log(NODE.label, NODE.stepLabel);

    //   NODE.stepLabel ? NAME = NODE.stepLabel : NAME = NODE.labem

    //   return NAME
    // },
    resetFilterByJourney() {
      this.currentClickedNodeId = null;
      this.filter.stepId = [];
      this.currentClickedNode = null;
      this.clickedsNodes = [];
      this.callAllMethodsFromReport();
    },
    async treeNodeClicked(info) {
      const NODE = await findNodeById(this.tree, info);

      if (NODE && (NODE.type === "sendSMS" || NODE.type === "sendEmail")) {
        this.currentClickedNodeId = info;
        this.currentClickedNode = NODE;

        if (!this.filter.stepId.find((x) => x === info))
          this.filter.stepId.push(info);
        else {
          const idRemove = this.filter.stepId.findIndex((x) => x === info);
          if (idRemove >= 0) this.filter.stepId.splice(idRemove, 1);
        }

        if (!this.clickedsNodes.find((d) => d.id == NODE.id))
          this.clickedsNodes.push(NODE);
        else {
          const IDX = this.clickedsNodes.findIndex((el) => el.id == NODE.id);
          if (IDX >= 0) this.clickedsNodes.splice(IDX, 1);
        }

        this.callAllMethodsFromReport();
      }
    },
    drillUpGraph(type) {
      const _ = require("lodash");

      if (type == "send") {
        let INDEX;

        for (
          let idx = 0;
          idx < this.sendChartDataInformationArr.length;
          idx++
        ) {
          if (
            _.isEqual(
              this.sendChartDataInformationArr[idx],
              this.sendChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.sendChartDataInformation =
            this.sendChartDataInformationArr[INDEX - 1];
          this.sendChartDataInformationArr.splice(INDEX);
        }
      }
      if (type == "engage") {
        let INDEX;

        for (
          let idx = 0;
          idx < this.engageChartDataInformationArr.length;
          idx++
        ) {
          if (
            _.isEqual(
              this.engageChartDataInformationArr[idx],
              this.engageChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.engageChartDataInformation =
            this.engageChartDataInformationArr[INDEX - 1];
          this.engageChartDataInformationArr.splice(INDEX);
        }
      }
    },
    async getChartClickedInfo(chart, e, type) {
      this.isFilteringGraph = true;
      const points = chart.getElementsAtEventForMode(
        e,
        "nearest",
        {
          intersect: true,
        },
        true
      );
      if (points.length) {
        //  const dataset = points[0].datasetIndex;
        // const CURRENT_CLICKED_VALUE = chart.data.datasets[dataset].data[index];
        const index = points[0].index;
        const CURRENT_CLICKED_LABEL = chart.data.labels[index];
        const LABELS_INFO = CURRENT_CLICKED_LABEL.split("/");

        const MONTHS = [
          "jan",
          "fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ];

        // ------- MÊS >> PARA >> DIA -------------------------
        if (MONTHS.includes(LABELS_INFO[0])) {
          const START_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .startOf("month")
            .format("YYYY-MM-DD");
          const END_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .endOf("month")
            .format("YYYY-MM-DD");

          // TROCAR O FILTRO PARA CORRESPONDER COM DAY OU MONTH
          // TROCAR O FILTRO PARA PEGAR O START E O END ATUAL DO ELEMENTO CLICADO...(PRIMEIRO DIA DO MÊS/ULTIMO DIA DO MÊS OU DATA ATUAL)
          this.filter.groupBy = "day";
          this.filter.startsAt = START_MONTH;
          this.filter.endsAt = END_MONTH;

          if (type == "send")
            await this.monthToDaySendChart(START_MONTH, END_MONTH, "day");

          if (type == "engage")
            await this.monthToDayEngageChart(START_MONTH, END_MONTH, "day");
        }

        // ------- DIA >>>> HORA -------------------------
        if (+LABELS_INFO[0] >= 0 && !CURRENT_CLICKED_LABEL.includes(":")) {
          this.isFilteringHour = true;
          const t = `${LABELS_INFO[2]}-${LABELS_INFO[1]}-${LABELS_INFO[0]}`;
          const START = moment(t).format("YYYY-MM-DD");
          const END = moment(t).add(1, "day").format("YYYY-MM-DD");

          if (type == "send")
            await this.monthToDaySendChart(START, END, "hour");
          if (type == "engage")
            await this.monthToDayEngageChart(START, END, "hour");

          this.isFilteringHour = false;
        }
      }

      this.isFilteringGraph = false;

      return;

      // chart.config.data = mock;
      // chart.update();
    },
    doughnutChartCancel() {
      this.filter.channel = "";
      this.callAllMethodsFromReport();
    },

    doughnutChartClicked(info) {
      // INSERIR CHANNEL NO FILTRO E CHAMAR TODAS AS REQUISIÇÕES DAS TELAS NOVAMENTE
      this.filter.channel = info;
      this.callAllMethodsFromReport();
    },

    async monthToDaySendChart(start, end, type) {
      this.sendChartDataInformationLoading = true;
      const INFO = await this.getSentQuantityToChart(start, end, type);

      if (INFO) {
        this.sendChartDataInformation = INFO;
        this.sendChartDataInformationArr.push(INFO);
      }
      this.sendChartDataInformationLoading = false;

      return;
    },

    async monthToDayEngageChart(start, end, type) {
      this.engageChartDataInformationLoading = true;

      const INFO = await this.getEngageQuantityToChart(start, end, type);

      if (INFO) {
        this.engageChartDataInformation = INFO;
        this.engageChartDataInformationArr.push(INFO);
      }

      this.engageChartDataInformationLoading = false;

      return;
    },

    async downloadDocument() {
      try {
        this.loadingDownloadUsers = true;

        const filter = {
          ...this.filter,
          ...this.templateTableFilter,
          userStatusFilter: this.userStatusFilter,
        };

        const response = await this.http.post(
          "reports/exportUserjouneysByJourney",
          filter
        );

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "users.csv";
        anchor.click();

        this.loadingDownloadUsers = false;
      } catch {
        this.loadingDownloadUsers = false;
        this.$toast.error("Ocorreu um erro ao exportar os dados!");
      }
    },

    async downloadDocumentTemplates() {
      try {
        this.loadingDownloadTemplates = true;

        const filter = {
          ...this.filter,
          ...this.templateTableFilter,
        };

        const response = await this.http.post(
          "reports/exportTemplateReportsByCompanyId",
          filter
        );

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "templates.csv";
        anchor.click();

        this.loadingDownloadTemplates = false;
      } catch {
        this.loadingDownloadTemplates = false;
        this.$toast.error("Ocorreu um erro ao exportar os dados!");
      }
    },

    mountLabelsMonth(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          DATES_ARR.push(start.format("MMM/YYYY"));
          start.add(1, "month");
        }
      }

      return DATES_ARR;
    },

    mountLabelsDays(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          DATES_ARR.push(start.format("DD/MM/YYYY"));
          start.add(1, "day");
        }
      }

      return DATES_ARR;
    },

    mountLabelsHour() {
      const DATES_ARR = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];

      return DATES_ARR;
    },

    async resetFilters() {
      this.selectedInterval = 1;
      await this.mountFilters();
      this.callAllMethodsFromReport();
    },
    verifyCustomDate() {
      const start = moment(this.filter.startsAt, "YYYY-MM-DD");
      const end = moment(this.filter.endsAt, "YYYY-MM-DD");
      const diference = end.diff(start, "days");

      if (diference <= 30) this.filter.groupBy = "day";
      else this.filter.groupBy = "month";

      return;
    },

    async chooseGroupBy(info = 7) {
      switch (info) {
        case 1:
          this.filter.groupBy = "month";
          break;
        case 2:
          this.filter.groupBy = "month";
          break;
        case 3:
          this.filter.groupBy = "month";
          break;
        case 4:
          this.filter.groupBy = "day";
          break;
        case 5:
          this.filter.groupBy = "day";
          break;
        case 6:
          await this.verifyCustomDate();
          break;
        case 7:
          this.filter.groupBy = "day";
          break;
      }

      this.selectedInterval = info;

      return;
    },
    getLasThirtyDays() {
      const START_DATE = moment().subtract(30, "days").format("DD-MM-YYYY");

      const END_DATE = moment().format("DD-MM-YYYY");

      return `${START_DATE} até ${END_DATE}`;
    },

    getLastSevenDays() {
      const START_DATE = moment().subtract(7, "days").format("DD-MM-YYYY");

      const END_DATE = moment().format("DD-MM-YYYY");

      return `${START_DATE} até ${END_DATE}`;
    },

    mountFilters() {
      let DATES = null;

      switch (this.selectedInterval) {
        case 1:
          DATES = this.getLastTwelveMonthsUltilDate().split("até");
          this.filter.groupBy = "month";
          break;
        case 2:
          DATES = this.getYearUntilDate().split("até");
          this.filter.groupBy = "month";
          break;
        case 3:
          DATES = this.getAllLastYear().split("até");
          this.filter.groupBy = "month";
          break;
        case 4:
          DATES = this.getMonthUntilToday().split("até");
          this.filter.groupBy = "day";
          break;
        case 5:
          DATES = this.getLastMonth().split("até");
          this.filter.groupBy = "day";
          break;
        case 7:
          DATES = this.getLasThirtyDays().split("até");
          this.filter.groupBy = "day";
          break;
      }

      if (this.selectedInterval < 6) {
        const START = DATES[0].trim().split("-");
        const END = DATES[1].trim().split("-");

        this.filter.startsAt = `${START[2]}-${START[1]}-${START[0]}`;
        this.filter.endsAt = `${END[2]}-${END[1]}-${END[0]}`;
      } else {
        this.filter.startsAt = "";
        this.filter.endsAt = "";
      }
      return;
    },

    actualizeTab(tab) {
      this.actualTab = tab;
    },

    getAllLastYear() {
      const START_YEAR_DATE = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("DD-MM-YYYY");

      const END_YEAR_DATE = moment(START_YEAR_DATE)
        .endOf("year")
        .format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${END_YEAR_DATE}`;

      return S;
    },

    getYearUntilDate() {
      const START_YEAR_DATE = moment().startOf("year").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastTwelveMonthsUltilDate() {
      const START = moment().subtract(12, "months").format("DD-MM-YYYY");
      const END = moment().format("DD-MM-YYYY");

      return `${START} até ${END}`;
    },

    getMonthUntilToday() {
      const START_MONTH_DATE = moment().startOf("month").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_MONTH_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastMonth() {
      const START_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("DD-MM-YYYY");

      const END_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("DD-MM-YYYY");

      return `${START_LAST_MONTH_DATE} até ${END_LAST_MONTH_DATE}`;
    },

    async callAllMethodsFromReport() {
      this.isLoading = true;

      const promises = [
        this.getChipValues(),
        this.getTotalOptOuts(),
        this.getAllJourneys(),
        // this.getSentQuantity(),
        this.getCurrentJourney(),
      ];

      await Promise.all(promises);

      this.getJourneyAudienceUniqueIds();
      this.getJourneyOptoutInfos();
      this.getJourneyBounceAndClickRateChartData();
      this.getUsersList();
      this.getTemplateReportsByCompanyId();

      setTimeout(() => {
        // this.mountInformationToCardsOnTop();
        this.isLoading = false;
      }, 1000);
    },

    async mountInformationToCardsOnTop() {
      let total_email = {
        title: "Enviados",
        info: this.totalEmailSent,
        icon: "$send",
        color: "rgb(246, 189, 2)",
      };

      let openeds = {
        color: "rgb(105, 188, 203)",
        icon: "mdi-email-open-outline",
        title: "Aberturas",
        info: `${this.totalEmailOpens} (${
          this.totalEmailSent !== 0
            ? ((this.totalEmailOpens / thi.totalEmailSent) * 100).toFixed(2)
            : 0
        }%)`,
      };

      let total_clicks = {
        title: "Total cliques",
        color: "rgba(73, 203, 140, 1)",
        icon: "$arrow_back",
        info: `${this.totalEmailClicks} (${
          this.totalEmailSent !== 0
            ? ((this.totalEmailClicks / this.totalEmailSent) * 100).toFixed(2)
            : 0
        }%)`,
      };

      let bounces = {
        title: "Bounces",
        icon: "mdi-email-sync-outline",
        color: "rgb(100, 45, 108)",
        info: `${this.totalEmailBounces} (${
          this.totalEmailSent !== 0
            ? ((this.totalEmailBounces / this.totalEmailSent) * 100).toFixed(2)
            : 0
        }%)`,
      };

      let spam = {
        title: "Spam",
        icon: "mdi-alert-octagon-outline",
        color: "#ffd2a9",
        info: `${this.totalEmailSpammed} (${
          this.totalEmailSent !== 0
            ? ((this.totalEmailSpammed / this.totalEmailSent) * 100).toFixed(2)
            : 0
        }%)`,
      };

      let optout = {
        title: "Optout",
        icon: "$unsubscribe",
        color: "#e7a2cc",
        info: `${this.totalOptOuts} (${
          this.totalEmailSent !== 0
            ? ((this.totalOptOuts / this.totalEmailSent) * 100).toFixed(2)
            : 0
        }%)`,
      };

      let reply = {
        title: "Reply",
        icon: "$reply",
        color: "#004c91",
        info: `${this.totalEmailSpammed} (${
          this.totalEmailSent !== 0
            ? `(${(
                (this.totalEmailSpammed / this.totalEmailSent) *
                100
              ).toFixed(2)})`
            : 0
        }%)`,
      };

      let audiences = {
        title: "Audiências",
        icon: "$groups",
        color: "rgb(222, 87, 66)",
        info: `${this.audienceUniqueIdsCounter}`,
      };

      this.informationsToCardsOnTop = [
        total_email,
        openeds,
        total_clicks,
        bounces,
        spam,
        optout,
        reply,
        audiences,
      ];
    },

    async filterReports() {
      if (this.selectedInterval !== 6) await this.callAllMethodsFromReport();
      else if (this.$refs.form.validate() && this.pickerRange)
        await this.callAllMethodsFromReport();
    },

    formatDate(date) {
      const DATE = moment(date).format("DD/MM/YYYY HH:mm");
      return DATE == "Invalid date" || DATE == "Data inválida"
        ? "Sem informação"
        : DATE;
    },

    formatDateWithTz(date) {
      const DATE = moment(date).add(-3, "hours").format("DD/MM/YYYY HH:mm");
      return DATE == "Invalid date" || DATE == "Data inválida"
        ? "Sem informação"
        : DATE;
    },

    formatDate2(date) {
      const DATE = moment(date).format("DD/MM/YYYY");
      return DATE == "Invalid date" || DATE == "Data inválida"
        ? "Sem informação"
        : DATE;
    },

    formatHour(date) {
      return moment(date).format("HH:mm");
    },

    async getNewJourneyInfos() {
      this.http
        .post("reports/getNewJourneyInfos", this.filter)
        .then((resp) => {
          this.newJourneyInfos = resp;
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    },
    async getAudienceList() {
      try {
        const res = await this.http.get("audience/list");
        this.audiencesList = res;
        return;
      } catch (error) {
        console.log(error);
      }

      return;
    },

    async gotToChoosenPage(page, table) {
      if (table == "user") {
        this.usersTableFilter.page = page;
        await this.getUsersList();
      }
      if (table == "template") {
        this.templateTableFilter.page = page;
        await this.getTemplateReportsByCompanyId();
      }
    },

    async goNextPageUsersList() {
      this.usersTableFilter.page++;
      await this.getUsersList();
    },

    async backPreviousPageUsersList() {
      this.usersTableFilter.page--;
      await this.getUsersList();
    },

    async goNextPageTemplatesReportsByCompanyId() {
      this.templateTableFilter.page++;
      await this.getTemplateReportsByCompanyId();
    },
    async backPreviousPageTemplatesReportsByCompanyId() {
      this.templateTableFilter.page--;
      await this.getTemplateReportsByCompanyId();
    },

    async filterUserList(userStatusFilter) {
      this.userStatusFilter = userStatusFilter;
      this.getUsersList();
    },

    async getUsersList() {
      try {
        this.loadingUserListTable = true;
        const URL = this.currentUserListUrl
          ? this.currentUserListUrl
          : "reports/getSomeUserjouneysByJourney";
        const res = await this.http.post(URL, {
          journeyId: this.$route.params.id,
          userStatusFilter: this.userStatusFilter,
          ...this.filter,
          ...this.usersTableFilter,
        });

        let mapped_items_ids = res.items.map((objeto, index) => {
          // Verificando se o objeto já possui uma propriedade "id"
          if (!objeto.hasOwnProperty.call(objeto, "id")) {
            return { ...objeto, id: `id_${index + 1}` };
          }

          // Caso o objeto já tenha uma propriedade "id", mantém o objeto inalterado
          return objeto;
        });

        this.usersList = mapped_items_ids;
        this.usersTableFilter.page = res.page;
        this.usersTableFilter.totalPages = res.pages;
        this.totalUsersPages = res.pages;
        this.loadingUserListTable = false;
        return;
      } catch (error) {
        console.log(error);
        this.loadingUserListTable = false;
      }

      return;
    },

    async getSortedUsersList(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }

      const URL = `reports/getSomeUserjouneysByJourney?sort=${name}&order=${ORDER}`;
      this.currentUserListUrl = URL;

      try {
        this.loadingUserListTable = true;
        const res = await this.http.post(URL, {
          journeyId: this.$route.params.id,
          ...this.filter,
          ...this.usersTableFilter,
        });

        let mapped_items_ids = res.items.map((objeto, index) => {
          // Verificando se o objeto já possui uma propriedade "id"
          if (!objeto.hasOwnProperty.call(objeto, "id")) {
            return { ...objeto, id: `id_${index + 1}` };
          }

          // Caso o objeto já tenha uma propriedade "id", mantém o objeto inalterado
          return objeto;
        });

        this.usersList = mapped_items_ids;
        this.usersTableFilter.page = res.page;
        this.usersTableFilter.totalPages = res.pages;
        this.totalUsersPages = res.pages;
        this.loadingUserListTable = false;
        return;
      } catch (error) {
        console.log(error);
        this.loadingUserListTable = false;
      }
    },

    async getTemplatesList() {
      try {
        const resp = await this.http.get("templatesConfiguration/list");

        this.templatesSMSList = resp.filter((d) => d.templateType === "sms");
        this.templatesEmailList = resp.filter(
          (d) => d.templateType === "email"
        );
        return;
      } catch (err) {
        console.log(err);
      }

      return;
    },

    async getJourneyBounceAndClickRateChartData() {
      let filterLocal = { ...this.filter };
      filterLocal.journeyId = this.$route.params.id;

      try {
        this.bouncesAndClicksChartDataLoading = true;
        const resp = await this.http.post(
          "reports/getJourneyBounceAndClickRateChartData",
          { ...filterLocal }
        );

        this.bouncesAndClicksChartDataInformation =
          await this.mountBouncesAndClicksGraphObject(resp);
        this.bouncesAndClicksChartDataLoading = false;

        return;
      } catch (error) {
        console.error(error);
        this.bouncesAndClicksChartDataLoading = false;
      }

      return;
    },

    async getJourneyExecutionData() {
      await this.http
        .post("reports/getJourneyExecutionData", {
          journeyId: this.$route.params.id,
        })
        .then((resp) => {
          this.firstSendDate = resp.firstSend;
          this.lastSendDate = resp.lastSend;
          this.sendDuration = resp.duration;
          this.remainingUsers = resp.remainingUsers;
          // this.audienceUniqueIdsCounter = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatDuration(duration) {
      if (!duration) {
        return "-";
      } else if (duration > 3600) {
        return duration / 3600 + " horas";
      } else if (duration > 60) {
        return duration / 60 + " minutos";
      } else {
        return duration + " segundos";
      }
    },

    async getJourneyAudienceUniqueIds() {
      this.loadingAudienceData = true;
      await this.http
        .post("reports/getJourneyAudienceUniqueIds", {
          journeyId: this.$route.params.id,
        })
        .then((resp) => {
          this.audienceUniqueIdsCounter = resp.count;
          this.audienceValidEmails = resp.validemails;
          this.emailsInOptOut = resp.emailsInOptOut;
          this.audienceValidPhones = resp.validnumbers;
          this.phonesInOptOut = resp.phonesInOptOut;
          this.loadingAudienceData = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingAudienceData = false;
        });
    },

    async getJourneyOptoutInfos() {
      this.loadingOptoutData = true;
      await this.http
        .post("reports/getJourneyOptoutInfos", {
          journeyId: this.$route.params.id,
        })
        .then((resp) => {
          this.audienceValidEmails = resp.validemails;
          this.emailsInOptOut = resp.emailsInOptOut;
          this.audienceValidPhones = resp.validnumbers;
          this.phonesInOptOut = resp.phonesInOptOut;
          this.loadingOptoutData = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingOptoutData = false;
        });
    },

    async getAllJourneys() {
      this.http
        .post("reports/getAllJourneys", this.filter)
        .then((resp) => {
          this.journeys = resp;
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    },

    async getTotalOptOuts() {
      let filterLocal = { ...this.filter };
      filterLocal.channel = "email";
      this.http
        .post("reports/getTotalOptOuts", filterLocal)
        .then((resp) => {
          this.totalOptOuts = resp.total;
        })
        .catch((err) => {
          console.log(err);
          this.totalOptOuts = 0;
        });

      return;
    },

    async getTotalByChannel() {
      try {
        const response = await this.http.post(
          "reports/getTotalByChannel",
          this.filter
        );
        this.totalByChannel = response;
        this.totalEmailSent = this.totalByChannel.emails;
        this.donutChartData = await this.mountDonutGraphObject(response);
      } catch (error) {
        console.log(error);
      }
      return;
    },

    async getSentQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        stepId: this.filter?.stepId,
      };
      try {
        const response = await this.http.post(
          "reports/getSentQuantityChartData",
          FILTER
        );
        this.sendChartDataInformationArr = [];

        // const mock = {
        //   sent: [1, 2, 3, 4, 5, 6, 7,8 , 9, 10],
        //   opened: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        //   clicked: [54, 34, 5, 6, 3, 5,6, 8, 9,34],
        // };

        this.sendChartDataInformation = await this.mountSentGraphObject(
          response
        );

        this.sendChartDataInformationArr.push(this.sendChartDataInformation);
      } catch (error) {
        console.error(error);
      }

      return;
    },

    async getSentQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: [this.filter?.selectedJourney],
        stepId: this.filter?.stepId,
      };

      let INFO = null;
      try {
        const response = await this.http.post(
          "reports/getSentQuantityChartData",
          FILTER
        );
        INFO = await this.mountSentGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    async getEngageQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: [this.filter?.selectedJourney],
        stepId: this.filter?.stepId,
      };
      try {
        const response = await this.http.post(
          "reports/getRateChartData",
          FILTER
        );
        this.engageChartDataInformationArr = [];

        this.engageChartDataInformation = await this.mountEngageGraphObject(
          response
        );
        this.engageChartDataInformationArr.push(
          this.engageChartDataInformation
        );
      } catch (error) {
        console.error(error);
      }

      return;
    },

    async getEngageQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: [this.filter?.selectedJourney],
        stepId: this.filter?.stepId,
      };
      let INFO = null;

      try {
        const response = await this.http.post(
          "reports/getRateChartData",
          FILTER
        );
        INFO = await this.mountEngageGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    async getJourneysReportsByCompanyId() {
      const URL = this.currentTableUrl
        ? this.currentTableUrl
        : "reports/getJourneysReportsByCompanyId";

      this.http
        .post(URL, this.filter)
        .then((resp) => {
          let temp = [...resp.items] || [];
          this.journeys = temp;
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    },

    async getTemplatesAndAudiencesList() {
      const PROMISES = [
        this.http.get("templatesConfiguration/list"),
        this.http.get("audience/list"),
      ];

      await Promise.all(PROMISES)
        .then((results) => {
          const [TEMPLATES, AUDIENCES] = results;

          this.templatesSMSList = TEMPLATES.filter(
            (d) => d.templateType === "sms"
          );
          this.templatesEmailList = TEMPLATES.filter(
            (d) => d.templateType === "email"
          );
          this.audiencesList = AUDIENCES;

          return;
        })
        .catch(() => {
          this.$toast.error("Error ao buscar templates e audiências!");
        });

      return;
    },

    async getTemplateReportsByCompanyId() {
      this.loadingSortTableTemplate = false;
      const URL = this.currentTemplateReportUrl
        ? this.currentTemplateReportUrl
        : "reports/getTemplateReportsByCompanyId";

      const filter = {
        ...this.filter,
        ...this.templateTableFilter,
      };
      this.loadingSortTableTemplate = true;

      this.http
        .post(URL, filter)
        .then((resp) => {
          this.templates = [...resp.items];
          this.templateTableFilter.page = resp.page;
          this.templateTableFilter.totalPages = resp.pages;
          this.loadingSortTableTemplate = false;
        })
        .catch((err) => {
          this.loadingSortTableTemplate = false;
          console.log(err);
        });
    },

    async getSortedTemplateReportsByCompanyId(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }
      const URL = `reports/getTemplateReportsByCompanyId?sort=${name}&order=${ORDER}`;
      this.currentTemplateReportUrl = URL;

      const filter = {
        ...this.filter,
        ...this.templateTableFilter,
      };

      try {
        this.loadingSortTableTemplate = true;
        const resp = await this.http.post(URL, filter);
        this.templates = [...resp.items];
        this.templateTableFilter.page = resp.page;
        this.templateTableFilter.totalPages = resp.pages;
        this.loadingSortTableTemplate = false;
      } catch (error) {
        this.$toast.error("Erro ao realizar ordenação");
        console.error(error);
        this.loadingSortTableTemplate = false;
      }
      // const URL = this.currentTableUrl
      //   ? this.currentTableUrl
      //   : "reports/getJourneysReportsByCompanyId";
      // this.http
      //   .post(URL, this.filter)
      //   .then((resp) => {
      //     this.journeys = resp;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },

    async getCurrentJourney() {
      try {
        const _ = require("lodash");
        this.isTreeLoading = true;
        const response = await this.http.get(
          `journey/findOne/${this.$route.params.id}`
        );
        this.currentJourney = response;
        this.journey = response.journey;

        // chamo aqui pois no primeiro carregamento, eu preciso obrigatóriamente da minha audiencia atual, para mandar o id no body do método abaixo
        // await this.getJourneyAudienceUniqueIds();

        const template_list_arr = _.merge(
          this.templatesEmailList,
          this.templatesSMSList
        );

        const obj = {
          status: "",
          steps_array: response.steps,
          current_audience_id: response.journey.audienceId,
          audience_list: this.audiencesList,
          template_sms_email_push_arr: template_list_arr,
        };

        const { tree } = await buildTreeService(obj);

        this.tree = tree;
        this.databaseConfigId = this.journey.audienceId;
        this.keyComponent++;
        this.isTreeLoading = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar informações");
        this.isTreeLoading = false;
      }

      return;
    },

    orderByModal() {
      this.getSortedJourneysReportsByCompanyId(
        this.tableName.value,
        this.orderName.value
      );

      this.closeOnContentClick = true;
      setTimeout(() => {
        this.closeOnContentClick = false;
      }, 300);
    },

    async getSortedJourneysReportsByCompanyId(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }
      const URL = `reports/getJourneysReportsByCompanyId?sort=${name}&order=${ORDER}`;
      this.currentTableUrl = URL;

      try {
        this.loadingSortTable = true;
        const resp = await this.http.post(URL, this.filter);
        this.journeys = resp;
        this.loadingSortTable = false;
      } catch (error) {
        this.$toast.error("Erro ao realizar ordenação");
        console.error(error);
        this.loadingSortTable = false;
      }
    },

    async chooseHowMountLabel(start, end, infoLength) {
      let LABELS = [];
      if (this.selectedInterval >= 4 || this.isFilteringGraph) {
        if (this.isFilteringHour) {
          LABELS = await this.mountLabelsHour();
        } else if (infoLength > 1 && this.filter.groupBy == "day") {
          LABELS = await this.mountLabelsDays(start, end);
        } else LABELS = await this.mountLabelsMonth(start, end);
      }

      this.selectedInterval < 4 &&
      !this.isFilteringGraph &&
      !this.isFilteringHour
        ? (LABELS = await this.mountLabelsMonth(start, end))
        : "";

      return LABELS || [];
    },
    async mountBouncesAndClicksGraphObject(info) {
      let chartData = {};
      let DATASETS = [
        { data: [], label: "Aberturas", backgroundColor: "#355E3B" },
        { data: [], label: "Cliques", backgroundColor: "#e7a2cc" },
        { data: [], label: "Bounces", backgroundColor: "rgb(246, 189, 2)" },
      ];
      const LABELS = [];
      const KEYS = Object.keys(info);

      for (let i = 0; i < KEYS.length; i++) {
        const KEY = KEYS[i];
        LABELS.push(info[KEY].stepNameForTemplate);
        DATASETS[0].data.push(info[KEY].openRate);
        DATASETS[1].data.push(info[KEY].clickRate);
        DATASETS[2].data.push(info[KEY].bounceRate);
      }

      chartData.labels = LABELS;
      chartData.datasets = DATASETS;

      return chartData;
    },
    async mountEngageGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.clickRate.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      datasets[1] = {
        data: info.clickRate,
        label: "Clicados",
        backgroundColor: "#00BBD3",
      };

      datasets[0] = {
        data: info.openRate,
        label: "Abertos",
        backgroundColor: "#B2D2D6",
      };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountSentGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.clicked.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      datasets[0] = {
        data: info.opened,
        label: "Abertos",
        backgroundColor: "#B2D2D6",
      };
      datasets[1] = {
        data: info.clicked,
        label: "Clicados",
        backgroundColor: "#00BBD3",
      };
      datasets[2] = {
        data: info.sent,
        label: "Enviados",
        backgroundColor: "#004C91",
      };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountDonutGraphObject(info) {
      const chart_data = {};
      if (info) {
        let LABELS = Object.keys(info);
        const DATAS = Object.values(info);

        const DATASETS = [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: DATAS,
          },
        ];

        chart_data.labels = LABELS;
        chart_data.datasets = DATASETS;
      }

      return chart_data;
    },

    // treatDataRange(arr) {
    //   let temp = [];
    //   for (let index = 0; index < 12; index++) {
    //     const i = arr.findIndex((item) => parseInt(item.month) === index + 1);
    //     if (i === -1) {
    //       temp.push(0);
    //     } else {
    //       temp.push(parseInt(arr[i].count));
    //     }
    //   }
    //   return temp;
    // },
    // async getOpenRateByDateRange() {
    //   this.http
    //     .post("reports/getOpenRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.openRateByDateRange = resp;
    //       this.opensData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // async getClickRateByDateRange() {
    //   this.http
    //     .post("reports/getClickRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.clickRateByDateRange = resp;
    //       this.clicksData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // async getTotalClicks() {
    //   this.http
    //     .post("reports/getTotalClicks", this.filter)
    //     .then((resp) => {
    //       this.totalClicks = resp.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    async getChipValues() {
      let resp = await this.http.post("reports/getChipValues", this.filter);

      this.totalSent = resp.total;
      this.totalClicks = resp.totalClicks;
      this.pushInfosCards = resp?.push;

      this.whatsappInfosCards = resp?.whatsapp;

      let emailValues = resp.email;

      this.totalByChannel = {};
      if (emailValues) {
        this.totalEmailSent = emailValues.count_total;
        this.totalEmailOpens = emailValues.count_opened;
        this.totalEmailDelivery = emailValues.count_delivered;
        this.totalEmailClicks = emailValues.count_clicked;
        this.totalEmailBounces = emailValues.count_bounced;
        this.totalEmailSpammed = emailValues.count_spam;

        this.totalByChannel.emails = emailValues.count_total;
      }

      let smsValues = resp.sms;

      if (smsValues) {
        this.totalSMSSent = smsValues.count_total;
        this.totalSMSClicks = smsValues.count_clicked;

        this.totalByChannel.sms = smsValues.count_total;
      }

      this.donutChartData = await this.mountDonutGraphObject(
        this.totalByChannel
      );
    },

    // async getTotalSent() {
    //   this.http
    //     .post("reports/getTotalSent", this.filter)
    //     .then((resp) => {
    //       this.totalSent = resp.total;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style>
.activeChip {
  background-color: rgb(25, 118, 210) !important;
  color: white !important;
}

.activeChip span {
  background-color: rgb(25, 118, 210) !important;
  color: white !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.tree-component {
  height: 380px;
  padding: $z-s-4 $z-s-2 $z-s-1;
  overflow-y: auto;
  @include trackScrollBar;
  display: flex;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    position: relative;

    &__overlay {
      position: absolute;
      inset: 0;
      background: rgba(248, 245, 246, 0.8);
      text-align: center;
      z-index: 5;

      .message {
        background: #666666;
        color: white;
        max-width: 60%;
        margin: 0 auto;
        margin-top: 10rem;
        padding: 1rem;
        border-radius: 10px;
        span {
          font-weight: 600;
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;

      .left {
        flex: 1 1 auto;

        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }

      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;

          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $z-s-2;

        .metric-type {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }
          small {
            font-weight: 500;
          }
        }
      }

      &__cards {
        display: grid;
        grid-template-columns: repeat(5, minmax(250px, 1fr));

        .item {
          position: relative;
          .dot {
            position: absolute;

            width: 13px;
            height: 13px;
            border-radius: 100% !important;
            top: 10px;
            left: 10px;
          }
        }

        @media (max-width: 1300px) {
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
      }

      // overflow-y: auto;
      // @include trackScrollBar;
      .table-wrapper {
        height: 450px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
          height: 250px;
        }

        @media (min-width: 1400px) {
          height: auto !important;
          max-height: 650px;
        }
      }

      // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;
              }

              &:last-child {
                text-align: right !important;
              }
            }

            td {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;

                & > div,
                span {
                  margin: 0 auto !important;
                }
              }

              &:last-child {
                text-align: right !important;
              }

              & > div,
              span {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }

    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;

  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;

    span {
      font-weight: 500;
    }

    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;

  &__title {
    text-align: center;
    font-weight: 500;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
